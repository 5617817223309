import React from "react";
import "./ContactUs.scss";
// import { graphql, useStaticQuery } from "gatsby"
// import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
} from "react-google-maps";
import Slide from "react-reveal/Slide";
import MapSvg from "../../../images/svg/map.svg"
import EmailSvg from "../../../images/svg/address.svg"
import EmpSvg from "../../../images/svg/employment.svg";

const Map = (props) => {
  return (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ lat: 14.57153644708504, lng: 121.04967997888647 }}
    >
      {props.isMarkerShown && (
        <Marker
          position={{ lat: 14.57153644708504, lng: 121.04967997888647 }}
        />
      )}
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

const ContactUs = () => {
  return (
    <>
      <div className="contact-us">
          <p className="title">
            Contact <span>Us</span>
          </p>

        <div className="contact-deets">
          {/* <div className="skewed-bg"></div> */}
          <div className="deets d-flex flex-row">
            <div className="flex-33 d-flex flex-column">
              <div className="deet">
                  <img src={MapSvg} width="65" alt="" />
                  <p className="title-head">Head Address</p>
                  <p className="text">
                    Unit 709 Globe Telcom Plaza 1, Pioneer Street, Mandaluyong
                    City 1552
                  </p>
                  <p className="text" style={{ color: `#ffeb3b` }}>
                    (02) 8570-4598
                  </p>
                </div>
            </div>
            <div className="flex-33 d-flex flex-column">
              <div className="deet">
                  <img src={EmpSvg} width="70" alt="" />
                  <p className="title-head">Employment</p>
                  <p className="text">
                    To apply for a job with Subic Grain, please send a cover
                    letter together with your C.V. to:
                  </p>
                  <p className="text" style={{ color: `#ffeb3b` }}>
                    {/* subicgrain@google.com */}
                    recruitment.subicgrain@gmail.com
                  </p>
                </div>
            </div>
            {/* <div className="flex-33 d-flex flex-column inquiry">
              <div className="deet">
                  <img src={EmailSvg} width="75" alt="" />
                  <p className="title-head">Inquiry</p>
                  <div className="d-flex flex-row">
                    <div className="flex-40">
                      <p className="text">
                      General Inquiry
                      </p>

                      <p className="text">
                      Careers Inquiry
                      </p>

                      <p className="text">
                      Service Inquiry
                      </p>
                    </div>

                    <div className="flex-60">
                      <p className="sub-title">
                        general@gmail.com
                      </p>
                      <p className="sub-title">
                        careers@gmail.com
                      </p>
                      <p className="sub-title">
                        service@gmail.com
                      </p>
                    </div>
                  </div>
                </div>
            </div> */}
          </div>
        </div>
     

        {/* <div className="map">
          <WrappedMap
            isMarkerShown
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAlxmrtcwL_dN4pJ99KAgN4ksusA3HMm58`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `70vh` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div> */}
      </div>
    </>
  );
};

export default ContactUs;
