import * as React from "react";
// import HeroSection from "../components/ContactUs/HeroSection";
import ContactUs from "../components/Home/ContactUs";
// import Inquiries from "../components/Home/Inquiries";
// import { Link } from "gatsby"

import Layout from "../components/layout";
import Seo from "../components/seo";

const Contact = () => (
  <Layout>
    <Seo title="Contact Us" />
    {/* <HeroSection /> */}
    <ContactUs />
    {/* <Inquiries /> */}
  </Layout>
);

export default Contact;
